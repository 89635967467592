<template>
	<div style="height: 100%">
		<div style="padding: 20px; height: 800px">
			<!-- 搜索栏 -->
			<el-form :inline="true" class="form" size="mini">
				<el-form-item label="订单编号">
					<el-input v-model="params.orderNum" placeholder="请输入订单编号"></el-input>
				</el-form-item>
				<el-form-item label="设备编号">
					<el-input v-model="params.deviceNum" placeholder="请输入设备编号"></el-input>
				</el-form-item>
				<el-form-item label="客户名">
					<el-input v-model="params.customerName" placeholder="请输入客户名"></el-input>
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input v-model="params.phone" placeholder="请输入联系电话"></el-input>
				</el-form-item>
				<el-form-item label="订单类型">
					<el-select v-model="params.orderType" placeholder="请选择订单类型">
						<el-option label="新订单" :value="1"></el-option>
						<el-option label="维修单" :value="2"></el-option>
						<el-option label="自提单" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="维修类型">
					<el-select v-model="params.repairType" placeholder="请选择维修类型">
						<el-option label="更换充电桩" :value="1"></el-option>
						<el-option label="更换漏保" :value="2"></el-option>
						<el-option label="地线维修" :value="3"></el-option>
						<el-option label="其他维修" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属公司">
					<el-select multiple filterable v-model="queryCompanyList" placeholder="请选择所属公司">
						<el-option
							v-for="item in companyDrownList"
							:key="item.companyId"
							:label="item.companyManager"
							:value="item.companyId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="下单充电桩类型">
					<el-select multiple filterable v-model="queryStoreIdList" placeholder="请选择充电桩类型">
						<el-option
							v-for="item in allDeviceCategoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="品牌" prop="deviceBrandId">
					<el-select multiple filterable v-model="queryDeviceBrandIdList" placeholder="请选择">
						<el-option
							v-for="item in deviceBrandDrownList"
							:key="item.id"
							:label="item.brandName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="车型" prop="modelId">
					<el-select multiple filterable v-model="queryModelIdList" placeholder="请选择">
						<el-option
							v-for="item in allDeviceModelDrownList"
							:key="item.id"
							:label="item.modelName + ' | ' + item.brandName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属区域" prop="regionId">
					<el-select multiple filterable v-model="queryRegionIdList" placeholder="请选择">
						<el-option
							v-for="item in regionList"
							:key="item.id"
							:label="item.regionName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属师傅">
					<el-select filterable v-model="params.masterWorkerId" placeholder="请选择">
						<el-option
							v-for="item in userList2"
							:key="item.id"
							:label="item.userName"
							:value="item.id"
						></el-option>
					</el-select>
					<!--      <el-input v-model="params.masterWorkerName" placeholder="请输入所属师傅"></el-input>-->
				</el-form-item>
				<el-form-item label="详细地址">
					<el-input v-model="params.detailAddress" placeholder="请输入详细地址"></el-input>
				</el-form-item>
				<el-form-item label="录单时间">
					<el-date-picker
						style="width: 210px"
						v-model="recordTime"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
						:default-time="['00:00:00', '23:59:59']"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="派单时间">
					<el-date-picker
						style="width: 210px"
						v-model="dispatchTime"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
						:default-time="['00:00:00', '23:59:59']"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="安装完工时间">
					<el-date-picker
						style="width: 210px"
						v-model="installTime"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
						:default-time="['00:00:00', '23:59:59']"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="完工提交时间">
					<el-date-picker
						style="width: 210px"
						v-model="installFinishTime"
						type="datetimerange"
						range-separator="至"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
						:default-time="['00:00:00', '23:59:59']"
					>
					</el-date-picker>
				</el-form-item>

        <el-form-item label="是否带桩上门">
          <el-radio-group v-model="params.bringStake" class="radio-group radio-group-merged">
            <el-radio :label="true" class="radio-item">是</el-radio>
            <el-radio :label="false" class="radio-item">否</el-radio>
          </el-radio-group>
        </el-form-item>

				<el-form-item label="多条件查询">
					<el-input
						style="width: 300px"
						v-model="params.webContent"
						placeholder="请输入车架号/预约单号/ID号/录单人/派单人/取消人"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="checkData" size="mini">查询</el-button>
					<el-button type="info" @click="reset" size="mini">重置</el-button>
				</el-form-item>
				<!-- 功能按钮 -->
			</el-form>
			<!-- 标签页 -->
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick" class="tab">
				<el-tab-pane name="-1">
					<span slot="label"
						>全部订单
						<el-badge :value="orderCount.totalCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="1">
					<span slot="label"
						>待首联
						<el-badge :value="orderCount.beFirstCount" class="item"></el-badge>
					</span>
				</el-tab-pane>

        <el-tab-pane name="14">
					<span slot="label"
          >三天跟进
						<el-badge :value="orderCount.followCount" class="item"></el-badge>
					</span>
        </el-tab-pane>

        <el-tab-pane name="15">
					<span slot="label"
          >待上游审核
						<el-badge :value="orderCount.beAuditedCount" class="item"></el-badge>
					</span>
        </el-tab-pane>

        <el-tab-pane name="16">
					<span slot="label"
          >今日待安装
						<el-badge :value="orderCount.beInstalledTodayCount" class="item"></el-badge>
					</span>
        </el-tab-pane>

				<el-tab-pane name="8">
					<span slot="label"
						>待派单
						<el-badge :value="orderCount.beLeaflets" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="9">
					<span slot="label"
						>电表申请中
						<el-badge :value="orderCount.meterCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="10">
					<span slot="label"
						>等客户通知
						<el-badge :value="orderCount.waitConfirmation" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<!-- <el-tab-pane name="13">
          <span slot="label">待勘测
            <el-badge :value="orderCount.surveyCount" class="item"></el-badge>
          </span>
        </el-tab-pane> -->
				<el-tab-pane name="2">
					<span slot="label"
						>待安装
						<el-badge :value="orderCount.beInstallCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="3">
					<span slot="label"
						>已安装待审核
						<el-badge :value="orderCount.installCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="6">
					<span slot="label"
						>已完工待结算
						<el-badge :value="orderCount.beSettledCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="7">
					<span slot="label"
						>已结算
						<el-badge :value="orderCount.settledCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="4">
					<span slot="label"
						>被驳回
						<el-badge :value="orderCount.rejectedCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="5">
					<span slot="label"
						>暂不安装
						<el-badge :value="orderCount.noInstallCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="11">
					<span slot="label"
						>取消待审核
						<el-badge :value="orderCount.cancelExamineCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
				<el-tab-pane name="12">
					<span slot="label"
						>已取消
						<el-badge :value="orderCount.cancelCount" class="item"></el-badge>
					</span>
				</el-tab-pane>
			</el-tabs>
			<!-- 表格 -->
			<div class="grid">
				<div style="float: right; margin: 0 10px 15px 0">
          <el-button
              type="success"
              size="mini"
              @click="batchPreOrder()"
              v-show="canShowPreOrder()"
          >批量预约</el-button
          >
					<el-button
						type="success"
						size="mini"
						@click="followMulti()"
						v-show="authorization('/home-admin/order/order-manage/addMultiFollow')"
						>跟进</el-button
					>
					<el-button
						type="success"
						size="mini"
						@click="download()"
						v-loading.fullscreen.lock="fullscreenLoading"
						v-show="authorization('/home-admin/order/order-manage/batchDownload')"
						>下载</el-button
					>
					<el-button
						type="success"
						size="mini"
						@click="toExcel(false)"
						v-loading.fullscreen.lock="fullscreenLoading"
						:loading="exportExcelLoading"
						v-show="authorization('/home-admin/order/order-manage/export')"
						>导出选中数据</el-button
					>
					<el-button
						type="success"
						size="mini"
						@click="toExcel(true)"
						v-loading.fullscreen.lock="fullscreenLoading"
						:loading="exportExcelLoading"
						v-show="authorization('/home-admin/order/order-manage/export')"
						>导出全部数据</el-button
					>
					<el-button
						type="success"
						@click="distribution()"
						size="mini"
						v-show="
							(activeName == 8 || activeName == -1) &&
							authorization('/home-admin/order/order-manage/distribution')
						"
						>派单</el-button
					>
					<el-button
						type="success"
						@click="batchExamines()"
						size="mini"
						v-show="(activeName == 3 || activeName == 15) && authorization('/home-admin/order/order-manage/batchExamine')"
						>批量审核</el-button
					>
					<!--          <el-button type="primary" @click="settlementOrder()" size="mini" v-show="(activeName==6)">确认结算</el-button>-->
					<el-button
						type="primary"
						@click="distribution()"
						size="mini"
						v-show="
							(activeName == 1 || activeName == 9 || activeName == 10) &&
							authorization('/home-admin/order/order-manage/replay')
						"
						>重新派单</el-button
					>
					<el-button
						type="warning"
						@click="cancel()"
						size="mini"
						v-show="authorization('/home-admin/order/order-manage/cancel')"
						>取消订单</el-button
					>
					<el-button
						type="danger"
						@click="kill()"
						size="mini"
						v-show="authorization('/home-admin/order/order-manage/kill')"
						>撤销派单</el-button
					>
					<el-button
						type="primary"
						@click="add()"
						size="mini"
						v-show="
							(activeName == 8 || activeName == -1) &&
							authorization('/home-admin/order/order-manage/add')
						"
						>新增</el-button
					>
					<el-dropdown
						v-show="
							(activeName == 8 || activeName == -1) &&
							authorization('/home-admin/order/order-manage/batchAdd')
						"
						class="btn-right"
					>
						<el-upload
							ref="excelImport"
							action="#"
							:on-change="beforeUpload"
							:limit="2"
							:show-file-list="false"
							:auto-upload="false"
							style="display: inline-block; padding: 0 10px"
						>
							<el-button type="primary" size="mini">批量新增</el-button>
						</el-upload>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item><a href="/批量导入模板.xlsx">下载模板</a></el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<el-table
					:data="tableData"
					ref="multipleTable"
					stripe
					height="84%"
					style="margin-left: 20px; width: 98%"
					size="small"
					border
					:header-cell-style="{
						'background-color': '#84aace4a',
						color: '#343333',
						'text-align': 'center',
					}"
					:cell-style="{ 'text-align': 'center' }"
					@selection-change="handleSelectionChange"
				>
					<!-- <el-table-column type="selection" width="55" v-if="activeName==8 || activeName==6 || activeName==1"></el-table-column> -->
					<el-table-column type="selection" width="45" fixed="left"></el-table-column>
					<el-table-column
						prop="orderNum"
						label="订单编号"
						width="180px"
						:show-overflow-tooltip="true"
					>
						<template slot-scope="scope">
							<span @click.prevent="details(scope.row)" style="cursor: pointer; color: #9292f6">{{
								scope.row.orderNum
							}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="pileModel" label="预约单号" width="150"></el-table-column>
					<el-table-column
						prop="orderType"
						label="订单类型"
						width="120"
						:formatter="orderTypeFormat"
					></el-table-column>
					<el-table-column prop="companyName" label="所属公司" width="150"></el-table-column>
					<el-table-column
						prop="storeName"
						label="下单充电桩类型"
						width="120"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column prop="customerName" label="客户名"></el-table-column>
					<el-table-column prop="phone" label="联系电话" width="100"></el-table-column>
					<el-table-column prop="provinceName" label="省" width="100"></el-table-column>
					<el-table-column prop="cityName" label="市" width="100"></el-table-column>
					<el-table-column prop="areaName" label="区县" width="100"></el-table-column>
					<el-table-column
						prop="detailAddress"
						label="详细地址"
						width="320px"
						:show-overflow-tooltip="true"
					></el-table-column>
					<!--          <el-table-column prop="price" label="订单金额"></el-table-column>-->
					<el-table-column
						prop="deviceBrandName"
						label="品牌"
						width="100px"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column
						prop="modelName"
						label="车型"
						width="100px"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column
						prop="regionName"
						label="所属区域"
						width="180px"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column prop="masterWorkerName" label="负责师傅" width="120px"></el-table-column>
					<el-table-column prop="status" label="状态" :formatter="statusFormat"></el-table-column>
					<el-table-column prop="recordUserName" label="录单人"></el-table-column>
					<el-table-column
						prop="recordTime"
						label="录单时间"
						:formatter="dateFormat"
						width="180px"
					></el-table-column>
					<el-table-column prop="dispatchUserName" label="派单人"></el-table-column>
					<el-table-column
						prop="dispatchTime"
						label="派单时间"
						:formatter="dateFormat"
						width="180px"
					></el-table-column>
					<el-table-column
						prop="subscribeTime"
						label="预约时间"
						:formatter="dateFormat"
						width="180px"
					></el-table-column>
					<el-table-column
						prop="installTime"
						label="完工时间"
						:formatter="dateFormat"
						width="180px"
					></el-table-column>
					<el-table-column
						prop="installFinishTime"
						label="完工提交时间"
						:formatter="dateFormat"
						width="180px"
					></el-table-column>
					<el-table-column prop="rejectedUser" label="驳回人"></el-table-column>
					<el-table-column
						prop="rejectedTime"
						label="驳回时间"
						width="180px"
						:formatter="dateFormat"
					></el-table-column>
					<el-table-column
						prop="rejectedReason"
						label="驳回原因"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column prop="cancelName" label="取消人"></el-table-column>
					<el-table-column
						prop="cancelTime"
						label="取消时间"
						:formatter="dateFormat"
						width="180px"
					></el-table-column>
					<el-table-column
						prop="cancelRemarks"
						label="取消备注"
						width="180px"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column prop="oldOrderNum" label="原订单号" width="180px">
						<template slot-scope="scope">
							<span
								@click.prevent="oldDetails(scope.row.oldOrderNum)"
								style="cursor: pointer; color: #9292f6"
								>{{ scope.row.oldOrderNum }}</span
							>
						</template>
					</el-table-column>
					<el-table-column
						prop="repairType"
						label="维修类型"
						:formatter="repairTypeFormat"
						width="100"
					></el-table-column>
					<el-table-column
						v-if="false"
						prop="followRemark"
						label="跟进备注"
						width="180px"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column label="操作" width="250px" fixed="right">
						<template slot-scope="scope">
							<el-tooltip effect="dark" content="审核" placement="top-end">
								<el-button
									content="审核"
									type="success"
									icon="el-icon-document-checked"
									style="margin-left: 10px"
									circle
									size="mini"
									v-show="
										authorization('/home-admin/order/order-manage/examine') && (scope.row.status == 3 || scope.row.status == 15)
									"
									@click="examines(scope.row)"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="取消审核" placement="top-end">
								<el-button
									content="取消审核"
									type="warning"
									icon="el-icon-s-platform"
									style="margin-left: 10px"
									circle
									size="mini"
									v-show="
										authorization('/home-admin/order/order-manage/cancelExamine') &&
										scope.row.status == 11
									"
									@click="examineCancelFun(scope.row)"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="详情" placement="top-end">
								<el-button
									type="info"
									icon="el-icon-tickets"
									circle
									size="mini"
									@click="details(scope.row)"
									style="margin-left: 10px"
									v-show="authorization('/home-admin/order/order-manage/detail')"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="唤起安装" placement="top-end">
								<el-button
									type="success"
									icon="el-icon-thumb"
									circle
									size="mini"
									@click="arouseClick(scope.row)"
									style="margin-left: 10px"
									v-show="
										authorization('/home-admin/order/order-manage/arouse') && scope.row.status === 5
									"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="唤起" placement="top-end">
								<el-button
									type="success"
									icon="el-icon-thumb"
									circle
									size="mini"
									@click="cancelArouse(scope.row)"
									style="margin-left: 10px"
									v-show="
										authorization('/home-admin/order/order-manage/cancelArouse') &&
										scope.row.status === 12
									"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="编辑" placement="top-end">
								<el-button
									type="primary"
									icon="el-icon-edit"
									circle
									size="mini"
									@click="edit(scope.row)"
									style="margin-left: 10px"
									v-show="editPowerFun(scope.row)"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="结算" placement="top-end">
								<el-button
									type="warning"
									icon="el-icon-s-finance"
									circle
									size="mini"
									@click="settlementOrder(scope.row)"
									style="margin-left: 10px"
									v-show="
										scope.row.status === 6 &&
										authorization('/home-admin/order/order-manage/settlement')
									"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="删除" placement="top-end">
								<el-popconfirm title="确认删除吗？" @confirm="deleteOrder(scope.row)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										circle
										size="mini"
										style="margin-left: 10px"
										v-show="deletePowerFun(scope.row)"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
							<el-tooltip effect="dark" content="操作记录" placement="top-end">
								<el-button
									type="info"
									content="操作记录"
									icon="el-icon-info"
									style="margin-left: 10px"
									circle
									size="mini"
									@click="record(scope.row)"
									v-show="authorization('/home-admin/routineManage/operate-record')"
								>
								</el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="跟进管理" placement="top-end">
								<el-button
									type="primary"
									content="跟进管理"
									icon="el-icon-document-add"
									style="margin-left: 10px"
									circle
									size="mini"
									@click="showFollow(scope.row)"
									v-show="authorization('/home-admin/order/order-manage/addFollow')"
								>
								</el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div
					style="
						padding-top: 20px;
						height: 40px;
						background-color: #fff;
						width: 100%;
						border-radius: 8px;
						text-align: right;
					"
				>
					<el-pagination
						background
						:page-sizes="sizes"
						layout="total,sizes,prev,pager,next"
						:total="total"
						:current-page.sync="currentPage"
						@current-change="handleCurrentChange"
						@size-change="sizeChange"
					></el-pagination>
				</div>
			</div>
		</div>
		<!-- 弹出层 -->
		<el-dialog
			title="新增"
			:visible.sync="dialogFormAdd"
			width="700px"
			:close-on-click-modal="false"
			top="10px"
		>
			<el-form
				:model="orderManageArray"
				:rules="rules"
				ref="orderManageArray"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="快速录入">
					<el-input type="textarea" v-model="textContent" @input="textInput"></el-input
					><el-button type="primary" @click="analysisData">快速录入</el-button>
					<br />
					<span style="color: #a1a0a0"
						>示例:ID 客户名 联系电话 详细地址 下单充电桩类型 预约单号 车架号</span
					>
				</el-form-item>
				<el-form-item label="所属公司" prop="companyId">
					<el-select
						filterable
						v-model="orderManageArray.companyId"
						@change="selectCompanyChange"
						placeholder="请选择所属公司"
						style="width: 100%"
					>
						<el-option
							v-for="item in companyDrownList"
							:key="item.companyId"
							:label="item.companyManager"
							:value="item.companyId"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="ID" prop="reserve2">
					<el-input v-model="orderManageArray.reserve2"></el-input>
				</el-form-item>
				<el-form-item label="客户名" prop="customerName">
					<el-input v-model="orderManageArray.customerName"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phone" type="number">
					<el-input v-model="orderManageArray.phone"></el-input>
				</el-form-item>
        <el-form-item label="是否带桩上门" prop="isDockingRequired">
          <el-switch v-model="orderManageArray.isDockingRequired" active-text="是" inactive-text="否"></el-switch>
        </el-form-item>
<!--				<el-form-item label="是否自布线" prop="isWiring">-->
<!--					<el-switch v-model="orderManageArray.isWiring" active-text="是" inactive-text="否"></el-switch>-->
<!--				</el-form-item>-->


<!--				<el-form-item label="住宅类型" prop="houseType">-->
<!--					<el-select-->
<!--							filterable-->
<!--							v-model="orderManageArray.houseType"-->
<!--							placeholder="请选择"-->
<!--							style="width: 100%"-->
<!--					>-->
<!--						<el-option-->
<!--								v-for="item in houseTypeList"-->
<!--								:key="item.id"-->
<!--								:label="item.houseTypeName"-->
<!--								:value="item.id"-->
<!--						></el-option>-->
<!--					</el-select>-->
<!--				</el-form-item>-->
        <el-form-item label="区域">
          <el-cascader
              size= "small"
              :options="options"
              v-model="fullLocalId">
          </el-cascader>
        </el-form-item>


        <el-form-item label="详细地址" prop="detailAddress">
					<el-input v-model="orderManageArray.detailAddress"></el-input>
				</el-form-item>
				<el-form-item label="品牌" prop="deviceBrandId">
					<el-select
						filterable
						v-model="orderManageArray.deviceBrandId"
						@change="selectDeviceBrandChanged"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceBrandDrownList"
							:key="item.id"
							:label="item.brandName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="车型"
					prop="modelId"
					v-show="orderManageArray.deviceBrandId != null && orderManageArray.deviceBrandId != ''"
				>
					<el-select
						filterable
						v-model="orderManageArray.modelId"
						@change="$forceUpdate()"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceModelDrownList"
							:key="item.id"
							:label="item.modelName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="下单充电桩类型" prop="storeId">
					<el-select
						filterable
						v-model="orderManageArray.storeId"
						@change="$forceUpdate()"
						placeholder="请选择充电桩类型"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceCategoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="预约单号" prop="pileModel">
					<el-input v-model="orderManageArray.pileModel"></el-input>
				</el-form-item>
				<el-form-item label="车架号">
					<el-input v-model="orderManageArray.frameNo"></el-input>
				</el-form-item>
				<!--          <el-form-item label="订单金额">
            <el-input v-model="orderManageArray.price" type="number"></el-input>
          </el-form-item>-->
				<el-form-item label="所属区域" prop="regionId">
					<el-select v-model="orderManageArray.regionId" placeholder="请选择" style="width: 100%">
						<el-option
							v-for="item in regionList"
							:key="item.id"
							:label="item.regionName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型" prop="orderType">
					<el-select v-model="orderManageArray.orderType" placeholder="请选择" style="width: 100%">
						<el-option label="新订单" :value="1"></el-option>
						<el-option label="维修单" :value="2"></el-option>
						<el-option label="自提单" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<div v-show="orderManageArray.orderType === 2">
					<el-form-item
						label="关联原订单号"
						prop="oldOrderNum"
						:rules="orderManageArray.orderType === 2 ? rules.oldOrderNum : [{ required: false }]"
					>
						<el-select
							filterable
							remote
							v-model="orderManageArray.oldOrderNum"
							:remote-method="oldOrderNumRemote"
							:loading="oldOrderNumLoading"
							placeholder="请选择"
							style="width: 100%"
						>
							<el-option
								v-for="item in orderSimpleInfoList"
								:key="item.orderNum"
								:label="item.orderNum"
								:value="item.orderNum"
							>
								<span style="float: left">{{ item.orderNum }}</span>
								<span style="float: right; margin-left: 10px; color: #8492a6">{{
									item.customerName +
									' | ' +
									item.phone +
									' | ' +
									item.frameNo +
									' | ' +
									item.storeName +
									' | ' +
									statusFormatByStatus(item.status)
								}}</span>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						label="维修类型"
						prop="repairType"
						:rules="orderManageArray.orderType === 2 ? rules.repairType : [{ required: false }]"
					>
						<el-select
							v-model="orderManageArray.repairType"
							placeholder="请选择"
							style="width: 100%"
						>
							<el-option label="更换充电桩" :value="1"></el-option>
							<el-option label="更换漏保" :value="2"></el-option>
							<el-option label="地线维修" :value="3"></el-option>
							<el-option label="其他维修" :value="4"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						label="故障描述"
						prop="faultDescription"
						:rules="
							orderManageArray.orderType === 2 ? rules.faultDescription : [{ required: false }]
						"
					>
						<el-input type="textarea" v-model="orderManageArray.faultDescription"></el-input>
					</el-form-item>
				</div>
				<el-form-item label="下单备注">
					<el-col :span="18">
						<el-input
							type="textarea"
							v-model="orderManageArray.orderRemark"
							@input="valueInput"
						></el-input>
					</el-col>
					<el-col :span="4">
						<el-button
							type="success"
							@click="shortcutModel = true"
							style="margin-left: 40px"
							size="small"
							>快捷语</el-button
						>
					</el-col>
				</el-form-item>
				<el-form-item label="跟进备注" v-if="false">
					<el-input type="textarea" v-model="orderManageArray.followRemark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="addSubMit('orderManageArray')" size="mini"
					>确 定</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			title="快捷语"
			:visible.sync="shortcutModel"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form size="small">
				<el-form-item label="选择快捷语">
					<el-select v-model="defaultShortcut" placeholder="请选择" style="width: 550px">
						<el-option
							v-for="item in shortcutOptions"
							:key="item.id"
							:label="item.quickWord"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button native-type="button" type="primary" @click="addShortcut" size="small"
					>确 定</el-button
				>
			</div>
		</el-dialog>
		<!-- 编辑弹出层 -->
		<el-dialog
			title="编辑"
			:visible.sync="dialogFormEdit"
			width="740px"
			:close-on-click-modal="false"
			top="10px"
		>
			<el-form
				:model="orderManageArray"
				:rules="rules"
				ref="orderManageArray"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="快速录入">
					<el-input type="textarea" v-model="textContent"></el-input
					><el-button size="small" type="primary" @click="analysisData">快速录入</el-button>
					<br />
					<span style="color: #a1a0a0"
						>示例:上游ID 客户名 联系电话 详细地址 所属门店 上游预约单号 车架号</span
					>
				</el-form-item>
				<el-form-item label="所属公司" size="medium">
					<el-select
						filterable
						v-model="orderManageArray.companyId"
						@change="selectCompanyChange"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option
							v-for="item in companyDrownList"
							:key="item.companyId"
							:label="item.companyManager"
							:value="item.companyId"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="ID" prop="reserve2">
					<el-input v-model="orderManageArray.reserve2"></el-input>
				</el-form-item>
				<el-form-item label="客户名" prop="customerName">
					<el-input v-model="orderManageArray.customerName"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phone" type="number">
					<el-input v-model="orderManageArray.phone"></el-input>
				</el-form-item>
        <el-form-item label="是否带桩上门" prop="isDockingRequired">
          <el-switch v-model="orderManageArray.isDockingRequired" active-text="是" inactive-text="否"></el-switch>
        </el-form-item>
<!--				<el-form-item label="是否自布线" prop="isWiring">-->
<!--					<el-switch v-model="orderManageArray.isWiring" active-text="是" inactive-text="否"></el-switch>-->
<!--				</el-form-item>-->

<!--				<el-form-item label="住宅类型" prop="houseType">-->
<!--					<el-select-->
<!--							filterable-->
<!--							v-model="orderManageArray.houseType"-->
<!--							placeholder="请选择"-->
<!--							style="width: 100%"-->
<!--					>-->
<!--						<el-option-->
<!--								v-for="item in houseTypeList"-->
<!--								:key="item.id"-->
<!--								:label="item.houseTypeName"-->
<!--								:value="item.id"-->
<!--						></el-option>-->
<!--					</el-select>-->
<!--				</el-form-item>-->
				<el-form-item label="区域">
					<el-cascader
						size= "small"
						:options="options"
						v-model="fullLocalId">
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="detailAddress">
					<el-input v-model="orderManageArray.detailAddress"></el-input>
				</el-form-item>
				<el-form-item label="品牌" prop="deviceBrandId">
					<el-select
						filterable
						v-model="orderManageArray.deviceBrandId"
						@change="selectDeviceBrandChanged"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceBrandDrownList"
							:key="item.id"
							:label="item.brandName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="车型"
					prop="modelId"
					v-show="orderManageArray.deviceBrandId != null && orderManageArray.deviceBrandId != ''"
				>
					<el-select
						filterable
						v-model="orderManageArray.modelId"
						@change="$forceUpdate()"
						placeholder="请选择"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceModelDrownList"
							:key="item.id"
							:label="item.modelName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="下单充电桩类型" prop="storeId">
					<el-select
						filterable
						v-model="orderManageArray.storeId"
						placeholder="请选择充电桩类型"
						@change="$forceUpdate()"
						style="width: 100%"
					>
						<el-option
							v-for="item in deviceCategoryList"
							:key="item.id"
							:label="item.categoryName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="预约单号" prop="pileModel">
					<el-input v-model="orderManageArray.pileModel"></el-input>
				</el-form-item>
				<el-form-item label="车架号">
					<el-input v-model="orderManageArray.frameNo"></el-input>
				</el-form-item>
				<!--          <el-form-item label="订单金额">
            <el-input v-model="orderManageArray.price" type="number"></el-input>
          </el-form-item>-->
				<el-form-item label="所属区域" prop="regionId">
					<el-select
						v-model="orderManageArray.regionId"
						placeholder="请选择"
						style="width: 100%"
						@change="$forceUpdate()"
					>
						<el-option
							v-for="item in regionList"
							:key="item.id"
							:label="item.regionName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型" prop="orderType">
					<el-select v-model="orderManageArray.orderType" placeholder="请选择" style="width: 100%">
						<el-option label="新订单" :value="1"></el-option>
						<el-option label="维修单" :value="2"></el-option>
						<el-option label="自提单" :value="3"></el-option>
					</el-select>
				</el-form-item>
				<div v-show="orderManageArray.orderType === 2">
					<el-form-item
						label="关联原订单号"
						prop="oldOrderNum"
						:rules="orderManageArray.orderType === 2 ? rules.oldOrderNum : [{ required: false }]"
					>
						<el-select
							filterable
							remote
							v-model="orderManageArray.oldOrderNum"
							:remote-method="oldOrderNumRemote"
							:loading="oldOrderNumLoading"
							placeholder="请选择"
							style="width: 100%"
						>
							<el-option
								v-for="item in orderSimpleInfoList"
								:key="item.orderNum"
								:label="item.orderNum"
								:value="item.orderNum"
							>
								<span style="float: left">{{ item.orderNum }}</span>
								<span style="float: right; margin-left: 10px; color: #8492a6">{{
									item.customerName +
									' | ' +
									item.phone +
									' | ' +
									item.frameNo +
									' | ' +
									item.storeName +
									' | ' +
									statusFormatByStatus(item.status)
								}}</span>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						label="维修类型"
						prop="repairType"
						:rules="orderManageArray.orderType === 2 ? rules.repairType : [{ required: false }]"
					>
						<el-select
							v-model="orderManageArray.repairType"
							placeholder="请选择"
							style="width: 100%"
						>
							<el-option label="更换充电桩" :value="1"></el-option>
							<el-option label="更换漏保" :value="2"></el-option>
							<el-option label="地线维修" :value="3"></el-option>
							<el-option label="其他维修" :value="4"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item
						label="故障描述"
						prop="faultDescription"
						:rules="
							orderManageArray.orderType === 2 ? rules.faultDescription : [{ required: false }]
						"
					>
						<el-input type="textarea" v-model="orderManageArray.faultDescription"></el-input>
					</el-form-item>
				</div>
				<el-form-item label="下单备注">
					<el-col :span="18">
						<el-input
							type="textarea"
							v-model="orderManageArray.orderRemark"
							@input="valueInput"
						></el-input>
					</el-col>
					<el-col :span="4">
						<el-button
							type="success"
							@click="shortcutModel = true"
							style="margin-left: 40px"
							size="small"
							>快捷语</el-button
						>
					</el-col>
				</el-form-item>
				<!-- <el-form-item label="跟进事项">
          <el-checkbox v-model="orderManageArray.isFirstLink">是否首联</el-checkbox>
          <el-checkbox v-model="orderManageArray.isBuildGroup">是否建群</el-checkbox>
          <el-checkbox v-model="orderManageArray.isFollow">是否跟进</el-checkbox>
        </el-form-item> -->
				<el-form-item label="跟进备注" v-if="false">
					<el-input type="textarea" v-model="orderManageArray.followRemark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="editSubMit('orderManageArray')" size="mini"
					>确 定</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			title="安装审核"
			:visible.sync="dialogFormExamine"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="orderManageArray"
				:rules="rules"
				ref="orderManageArray"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="驳回理由">
					<el-input
						type="textarea"
						:autosize="{ minRows: 2 }"
						placeholder="请输入驳回理由（通过则不填写）"
						v-model="orderManageArray.examineArea"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 60px">
				<el-popconfirm title="确认审核通过吗？" @confirm="examineOrder('orderManageArray', 6)">
					<el-button slot="reference" type="success" size="mini">通过</el-button>
				</el-popconfirm>
				<el-popconfirm
					title="确认驳回吗？"
					@confirm="examineOrder('orderManageArray', 4)"
					style="margin-left: 10px"
				>
					<el-button slot="reference" type="danger" size="mini">驳回</el-button>
				</el-popconfirm>
			</div>
		</el-dialog>
		<el-dialog
			title="取消审核"
			:visible.sync="dialogFormExamineCancel"
			width="30%"
			:close-on-click-modal="false"
		>
			<span slot="footer" class="dialog-footer">
				<el-popconfirm title="确认审核通过吗？" @confirm="examineOrderCancel('examineCancel', 12)">
					<el-button slot="reference" type="success">通过</el-button>
				</el-popconfirm>
				<el-popconfirm
					title="确认驳回吗？"
					@confirm="examineOrderCancel('examineCancel', 2)"
					style="margin-left: 10px"
				>
					<el-button slot="reference" type="danger">驳回</el-button>
				</el-popconfirm>
			</span>
		</el-dialog>
		<el-dialog
			title="派单"
			:visible.sync="dialogFormDistribution"
			width="500px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="orderManageArray"
				:rules="rules2"
				ref="orderManageArray"
				label-width="160px"
				size="small"
			>
				<el-form-item label="负责师傅" prop="userId">
					<el-select filterable v-model="orderManageArray.userId" placeholder="请选择">
						<el-option
							v-for="item in userList"
							:key="item.id"
							:label="item.userName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
				<el-button @click="dialogFormDistribution = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="distributionSubMit('orderManageArray')" size="mini"
					>确 定</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			title="批量导入订单"
			:visible.sync="dialogFormBatchAdd"
			width="1000px"
			:close-on-click-modal="false"
		>
			<div style="height: 500px; overflow: auto">
				<el-table
					size="mini"
					:data="formBatchList"
					stripe
					border
					ref="multipleTable"
					:cell-style="{ 'text-align': 'center' }"
					:header-cell-style="{
						background: 'rgba(132,170,206,0.29)',
						color: '#343333',
						'text-align': 'center',
					}"
				>
					<el-table-column prop="companyName" label="所属公司" width="120px">
						<template slot-scope="scope">
							<div v-if="scope.row.companyId == null">
								<el-tooltip :content="scope.row.companyName + '在系统中不存在'"
									><span style="color: red">{{ scope.row.companyName }}</span></el-tooltip
								>
							</div>
							<div v-else>
								<span>{{ scope.row.companyName }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="reserve2" label="ID" width="120px"></el-table-column>
					<el-table-column prop="customerName" label="客户名" width="120px"></el-table-column>
					<el-table-column prop="phone" label="联系电话" width="120px"></el-table-column>
					<el-table-column
						prop="detailAddress"
						label="详细地址"
						width="255px"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column prop="brandName" label="品牌" width="120px">
						<template slot-scope="scope">
							<div v-if="scope.row.brandId == null">
								<el-tooltip :content="scope.row.brandName + '在系统中不存在'"
									><span style="color: red">{{ scope.row.brandName }}</span></el-tooltip
								>
							</div>
							<div v-else>
								<span>{{ scope.row.brandName }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="modelName" label="车型" width="120px">
						<template slot-scope="scope">
							<div v-if="scope.row.modelId == null">
								<el-tooltip :content="scope.row.modelName + '在系统中不存在'"
									><span style="color: red">{{ scope.row.modelName }}</span></el-tooltip
								>
							</div>
							<div v-else>
								<span>{{ scope.row.modelName }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="storeName" label="下单充电桩类型" width="150px">
						<template slot-scope="scope">
							<div v-if="scope.row.storeId == null">
								<el-tooltip :content="scope.row.storeName + '在系统中不存在'"
									><span style="color: red">{{ scope.row.storeName }}</span></el-tooltip
								>
							</div>
							<div v-else>
								<span>{{ scope.row.storeName }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="pileModel" label="预约单号" width="150px"></el-table-column>
					<el-table-column prop="frameNo" label="车架号" width="150px"></el-table-column>
					<el-table-column prop="regionName" label="所属区域" width="120px">
						<template slot-scope="scope">
							<div v-if="scope.row.regionId == null">
								<el-tooltip :content="scope.row.regionName + '在系统中不存在'"
									><span style="color: red">{{ scope.row.regionName }}</span></el-tooltip
								>
							</div>
							<div v-else>
								<span>{{ scope.row.regionName }}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div slot="footer">
				<el-button @click="dialogFormBatchAdd = false" size="small">取 消</el-button>
				<el-button
					:loading="dialogFormBatchAddLoading"
					type="primary"
					@click="confirmBatchAdd"
					size="small"
					>确认导入</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			title="取消订单"
			:visible.sync="dialogFormCancel"
			width="800px"
			:close-on-click-modal="false"
		>
			<el-form
				:model="orderManageArray"
				:rules="rules"
				ref="orderManageArray"
				label-width="160px"
				size="small"
				style="width: 600px"
			>
				<el-form-item label="取消原因">
					<el-input
						type="textarea"
						:autosize="{ minRows: 2 }"
						placeholder="请输入取消原因"
						v-model="orderManageArray.cancelRemarks"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 60px">
				<el-button type="info" size="mini" @click="dialogFormCancel = false">取消</el-button>
				<el-button type="success" size="mini" @click="cancelOrder('orderManageArray')"
					>确定</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			title="结算订单"
			:visible.sync="dialogFormSettlement"
			width="1000px"
			:close-on-click-modal="false"
		>
			<el-form :model="settlementParams" :rules="rules" ref="settlementParams" size="small">
				<el-descriptions border :column="1" :labelStyle="{ width: '135px' }">
					<el-descriptions-item label="上游所给订单费用" style="width: 200px">
						<el-input
							placeholder="请输入订单费用"
							type="number"
							v-model="settlementParams.orderPrice"
							@input="calculationPrice"
						></el-input>
					</el-descriptions-item>
				</el-descriptions>
				<el-descriptions border :column="3" :labelStyle="{ width: '135px' }">
					<el-descriptions-item label="师傅抽成方式">{{
						settlementParams.masterTaskType === 1 ? '比例抽成(百分比)' : '具体金额'
					}}</el-descriptions-item>
					<el-descriptions-item label="师傅抽成值">
						<el-input
							placeholder="请输入抽成值"
							type="number"
							v-model="settlementParams.masterTaskValue"
							@input="calculationPrice"
						></el-input>
					</el-descriptions-item>
					<el-descriptions-item label="师傅订单所得费用">
						<span style="color: red">{{ settlementParams.masterOrderPrice }}</span>
					</el-descriptions-item>
					<el-descriptions-item label="业务员抽成方式">{{
						settlementParams.salesmanTaskType === 1 ? '比例抽成(百分比)' : '具体金额'
					}}</el-descriptions-item>
					<el-descriptions-item label="业务员抽成值">
						<el-input
							placeholder="请输入抽成值"
							type="number"
							v-model="settlementParams.salesmanTaskValue"
							@input="calculationPrice"
						></el-input>
					</el-descriptions-item>
					<el-descriptions-item label="业务员订单所得费用">
						<span style="color: red">{{ settlementParams.salesmanPrice }}</span>
					</el-descriptions-item>
					<el-descriptions-item label="增项费用">
						<el-input
							placeholder="请输入增项费用"
							type="number"
							v-model="settlementParams.additionsPrice"
							@input="calculationPrice"
						></el-input>
					</el-descriptions-item>
					<el-descriptions-item label="增项费用抽成比例">
						<el-input
							placeholder="请输入增项费用抽成比例"
							type="number"
							v-model="settlementParams.additionsTask"
							@input="calculationPrice"
						></el-input>
					</el-descriptions-item>
					<el-descriptions-item label="师傅增项所得费用">
						<span style="color: red">{{ settlementParams.masterAdditionsPrice }}</span>
					</el-descriptions-item>
					<el-descriptions-item label="师傅最终所得费用">
						<span style="color: red">{{ settlementParams.masterPrice }}</span>
					</el-descriptions-item>
					<el-descriptions-item label="增项盈利费用">
						<span style="color: red">{{ settlementParams.additionsProfitPrice }}</span>
					</el-descriptions-item>
					<el-descriptions-item label="订单盈利费用">
						<span style="color: red">{{ settlementParams.orderProfitPrice }}</span>
					</el-descriptions-item>
					<el-descriptions-item label="最终盈利总费用">
						<span style="color: red">{{ settlementParams.profitPrice }}</span>
					</el-descriptions-item>
				</el-descriptions>
			</el-form>
			<div slot="footer" style="margin-top: 10px">
				<el-button type="info" size="mini" @click="dialogFormSettlement = false">取消</el-button>
				<el-button type="success" size="mini" @click="settleSubMit('settlementParams')"
					>确定</el-button
				>
			</div>
		</el-dialog>
		<el-dialog
			title="操作记录"
			:visible.sync="dialogFormRecord"
			width="800px"
			height="480px"
			:modal="true"
			:lock-scroll="true"
			:close-on-click-modal="false"
		>
			<div v-loading="isLoadRecordData">
				<el-empty
					description="订单无操作记录~"
					v-show="isRecordEntryEmpty && !isLoadRecordData"
				></el-empty>
				<el-timeline :reverse="recordParams.reverse" v-show="isRecordEntry && !isLoadRecordData">
					<el-timeline-item
						v-for="(record, index) in recordList"
						:key="index"
						:timestamp="CreateTimeFormat(record.createTime)"
						placement="top"
					>
						<el-descriptions :title="record.operateType" direction="horizontal" :column="1">
							<el-descriptions-item label="操作人">
								{{ record.operator }}
							</el-descriptions-item>
							<el-descriptions-item label="备注">
								{{ record.remark }}
							</el-descriptions-item>
						</el-descriptions>
					</el-timeline-item>
				</el-timeline>
			</div>
			<div slot="footer" style="margin-top: 10px">
				<el-button type="info" size="mini" @click="dialogFormRecord = false"> 取消 </el-button>
			</div>
		</el-dialog>
		<el-dialog
			title="跟进管理"
			:visible.sync="dialogFormFollow"
			width="1000px"
			:close-on-click-modal="false"
			>
				<div style="padding-left: 20px; padding-right: 20px; height: 600px">
				<!-- 表格 -->
					<div style="height: 82%; background-color: #fff; border-radius: 8px; padding-top: 0px">
						<el-button
							type="primary"
							size="small"
							style="float: right; margin-top: 0px; margin-right: 20px; margin-bottom: 20px"
							@click="addFollow(selectOrderId.orderId)"
							v-show="authorization('/home-admin/order/order-manage/addFollow')"
							>新增</el-button
						>
						<el-table
						:data="modelTableData"
						stripe
						style="padding: 0 20px"
						height="80%"
						size="small"
						:header-cell-style="{
							'background-color': '#84aace4a',
							color: '#343333',
							'text-align': 'center',
						}"
						:cell-style="{ 'text-align': 'center' }"
					>
						<el-table-column prop="customerName" label="客户姓名"></el-table-column>
						<el-table-column prop="phone" label="客户电话"></el-table-column>
						<el-table-column prop="followInfo" label="跟进备注"></el-table-column>
						<el-table-column prop="followerName" label="跟进人"></el-table-column>
						<el-table-column
							prop="followTime"
							label="跟进时间"
							:formatter="dateFormat"
						></el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-tooltip
									effect="dark"
									content="编辑"
									placement="top-end"
									v-show="authorization('/home-admin/order/order-manage/editFollow')"
								>
									<el-button
										type="primary"
										icon="el-icon-edit"
										circle
										size="mini"
										@click="editFollow(scope.row)"
										style="margin-right: 5px"
									></el-button>
								</el-tooltip>
								<el-tooltip
									effect="dark"
									content="删除"
									placement="top-end"
									v-show="authorization('/home-admin/order/order-manage/delFollow')"
								>
									<el-popconfirm title="确认删除吗？" @confirm="delFollow(scope.row)">
										<el-button
											content="删除"
											slot="reference"
											type="danger"
											icon="el-icon-delete"
											circle
											size="mini"
										></el-button>
									</el-popconfirm>
								</el-tooltip>
							</template>
						</el-table-column>
						</el-table>
					</div>
				</div>
		</el-dialog>
		<el-dialog
			title="跟进"
			:visible.sync="dialogMultiFollow"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
					label-width="160px"
					size="small"
					style="width: 600px">
				<el-form-item label="跟进备注">
					<el-input
					type="textarea"
					:autosize="{minRows: 4}"
					placeholder="请输入内容"
					v-model="followMsg">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
					<el-button @click="dialogMultiFollow = false" size="mini">取 消</el-button>
					<el-button type="primary" @click="multiFollowSubMit()" size="mini">确 定</el-button>
				</div>
		</el-dialog>

    <el-dialog
        title="批量预约安装"
        :visible.sync="dialogFormPreOrder"
        width="700px"
        :close-on-click-modal="false"
    >
      <el-form
          label-width="160px"
          size="small"
          style="width: 600px">
        <el-form-item label="预约时间">
          <el-date-picker
              v-model="preOrderTime"
              type="datetime"
              placeholder="选择预约时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="{
               disabledDate(time) {
                  // 允许今天及以后的日期，禁用今天之前的日期
                  return time.getTime() < Date.now()-86400000;
                }
              }">
          </el-date-picker>

        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="closePreOrderDialog()" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitPreOrder()" size="mini">确 定</el-button>
      </div>
    </el-dialog>



    <el-dialog
			title="新增"
			:visible.sync="dialogFollowAdd"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
					:model="followArr"
					:rules="rules3"
					ref="followArr"
					label-width="160px"
					size="small"
					style="width: 600px"
				>
				<el-form-item label="客户姓名" prop="customerName" v-show="false">
					<el-input v-model="followArr.customerName"></el-input>
				</el-form-item>
				<el-form-item label="客户电话" prop="phone" v-show="false">
					<el-input v-model="followArr.phone" :min="0"></el-input>
				</el-form-item>
				<el-form-item label="跟进备注" prop="remark">
					<el-input
					type="textarea"
					:autosize="{minRows: 4}"
					placeholder="请输入内容"
					v-model="followArr.followRemark">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
					<el-button @click="dialogFollowAdd = false" size="mini">取 消</el-button>
					<el-button type="primary" @click="addFollowSubMit('followArr')" size="mini">确 定</el-button>
				</div>
		</el-dialog>

		<el-dialog
			title="编辑"
			:visible.sync="dialogFollowEdit"
			width="700px"
			:close-on-click-modal="false"
		>
			<el-form
					:model="followEdit"
					:rules="rules3"
					ref="followEdit"
					label-width="160px"
					size="small"
					style="width: 600px"
				>
				<el-form-item label="客户姓名" prop="customerName" v-show="false">
					<el-input v-model="followEdit.customerName"></el-input>
				</el-form-item>
				<el-form-item label="客户电话" prop="phone" v-show="false">
					<el-input v-model="followEdit.phone" :min="0"></el-input>
				</el-form-item>
				<el-form-item label="跟进备注" prop="remark">
					<el-input
					type="textarea"
					:autosize="{ minRows: 4}"
					placeholder="请输入内容"
					v-model="followEdit.followRemark">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="margin-top: 50px">
					<el-button @click="dialogFollowEdit = false" size="mini">取 消</el-button>
					<el-button type="primary" @click="editFollowSubMit('followEdit')" size="mini">确 定</el-button>
				</div>
		</el-dialog>
	</div>
</template>

<script>
	import moment from 'moment';
	import { regionData, codeToText } from 'element-china-area-data'
	import { authorization } from '../../../authorization/authorization';
	import {
		statusFormat,
		statusFormatByStatus,
		orderTypeFormat,
		repairTypeFormat,
	} from '../common/format';
	export default {
		data() {
			return {
				options: regionData,
				fullLocalId: '',
				oldOrderNumLoading: false,
				exportExcelLoading: false,
				dialogFormSettlement: false,
				fullscreenLoading: false,
				dialogFormExamineCancel: false,
				dialogFollowAdd: false,
				dialogFollowEdit: false,
				dialogMultiFollow: false,
				defaultShortcut: '',
				shortcutOptions: [],
				modelTableData: [],
				followMsg: '',
				preOrderTime: '',
				settlementParams: {
					orderId: '',
					orderPrice: 0,
					masterTaskType: '',
					masterTaskValue: 0,
					salesmanTaskType: '',
					salesmanTaskValue: 0,
					additionsTask: '',
					additionsPrice: 0,
					materialPrice: 0,
					masterAdditionsPrice: 0,
					masterOrderPrice: 0,
					masterPrice: 0,
					salesmanPrice: 0,
					orderProfitPrice: 0,
					additionsProfitPrice: 0,
					materialProfitPrice: 0,
					profitPrice: 0,
				},
				addSubStatus: true,
				shortcutModel: false,
				status: '',
				batchUUid: '',
				dialogFormBatchAdd: false,
				dialogFormBatchAddLoading: false,
				formBatchList: [],
				textContent: '',
				userId: Number(sessionStorage.getItem('userId')),
				dialogFormAdd: false,
				dialogFormEdit: false,
				dialogFormDistribution: false,
				dialogFormExamine: false,
                dialogFormPreOrder: false,
				dialogFormCancel: false,
				dialogFormRecord: false,
				dialogFormFollow: false,
				tableData: [],
				multipleSelection: [],
				selectedOrders: [],
				followList: [],
				regionList: [],
				userList: [],
				userList2: [],
				receivingOrder: 0,
				activeName:
					this.$route.query.activeName === undefined ? '-1' : this.$route.query.activeName,
				page: this.$route.query.page === undefined ? 1 : this.$route.query.page,
				recordTime: [],
				dispatchTime: [],
				installTime: [],
				installFinishTime: [],
				allDeviceCategoryList: [],
				deviceCategoryList: [],
				queryRegionIdList: [],
				queryStoreIdList: [],
				queryCompanyList: [],
				queryDeviceBrandIdList: [],
				queryModelIdList: [],
				selectOrderId: {
					orderId: '',
				},
				params: {
					status: '',
					bringStake: '',
					orderNum: '',
					deviceNum: '',
					customerName: '',
					phone: '',
					storeId: '',
					masterWorkerName: '',
					recordTime: '',
					dispatchTime: '',
					installTime: '',
          isDockingRequired: "",
          subscribeTime: '',
					installFinishTime: '',
					regionId: '',
					detailAddress: '',
					masterWorkerId: '',
					page: 1,
					size: 10,
					webContent: '',
				},
				examine: {
					orderId: '',
					orderIds: [],
					rejectRemark: '',
					type: '',
				},
				examineCancel: {
					orderId: '',
					cancelRemarks: '',
					type: '',
				},
				orderManageArray: {
					id: '',
					storeId: '',
					customerName: '',
					phone: '',
					detailAddress: '',
					pileModel: '',
					frameNo: '',
					houseType: '',
					price: '',
					reserve2: '',
					companyId: '',
					regionId: '',
					orderRemark: '',
					orderId: [],
					userId: '',
					examineArea: '',
					orderIds: [],
					cancelRemarks: '',
					regionIdList: [],
					deviceBrandId: '',
					brandId: '',
					orderType: '',
					modelId: '',
					isDockingRequired: '',
					isWiring: '',
					localList: [],
					provinceName: '',
					cityName: '',
					areaName: ''
					// isFirstLink: false,
					// isBuildGroup: false,
					// isFollow: false
				},
				arouseParams: {
					orderId: '',
					id: '',
					status: '',
				},
				recordParams: {
					reverse: true,
					orderId: 0,
				},
				//用于新增跟进
				followArr: {
					orderId: '',
					customerName: '',
					phone: '',
					followRemark: '',
				},
				followEdit: {
					id: '',
					customerName: '',
					phone: '',
					followRemark: '',
				},
				recordList: [],
				isRecordEntry: false,
				isRecordEntryEmpty: true,
				isLoadRecordData: true,
				orderIdList: [],
				rules: {
					companyId: [{ required: true, message: '请选择所属公司', trigger: 'blur' }],
					storeId: [{ required: true, message: '请选择充电桩类型', trigger: 'blur' }],
					customerName: [{ required: true, message: '请输入客户名', trigger: 'blur' }],
					phone: [
						{ required: true, message: '请输入联系电话', trigger: 'blur' },
						{ validator: this.validatePhone },
					],
					detailAddress: [
						{ required: true, message: '请输入详细地址', trigger: 'blur' },
						{ min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' },
					],
					deviceBrandId: [{ required: true, message: '请选择品牌', trigger: 'blur' }],
					modelId: [{ required: true, message: '请选择车型', trigger: 'blur' }],
					regionId: [{ required: true, message: '请选择区域', trigger: 'blur' }],
					orderType: [{ required: true, message: '请选择订单类型', trigger: 'blur' }],
					oldOrderNum: [{ required: true, message: '请选择关联原订单', trigger: 'blur' }],
					repairType: [{ required: true, message: '请选择维修类型', trigger: 'blur' }],
          // 是否带桩上门的验证规则
					isDockingRequired: [
						{ required: true, message: '请选择是否带桩上门', trigger: 'blur' } // 必选项
					],
					isWiring:[
						{ required: true, message: '请选择是否自布线', trigger: 'blur' } // 必选项

					],
					houseType:[
						{ required: true, message: '请选择住宅类型', trigger: 'blur' } // 必选项

					],
					faultDescription: [{ required: true, message: '请填写故障描述', trigger: 'blur' }],
				},
				rules2: {
					userId: [{ required: true, message: '请选择所属师傅', trigger: 'blur' }],
				},
				rules3: {
					customerName: [{ required: true, message: '请输入客户名', trigger: 'blur' }],
					phone: [
						{ required: true, message: '请输入联系电话', trigger: 'blur' },
						{ validator: this.validatePhone },
					],
				},
				currentPage: 1,
				total: 0,
				sizes: [10, 15, -1],
				orderCount: {},
				companyDrownList: [],
				deviceBrandDrownList: [],
				houseTypeList: [
					{
						id: 0,
						houseTypeName: '农村自建房'

					},
					{
						id: 1,
						houseTypeName: '城市小区'
					},
					{
						id: 2,
						houseTypeName: '城市自建房'
					},
					{
						id: 3,
						houseTypeName: '工厂'
					},
					{
						id: 4,
						houseTypeName: '其他'
					}
				],
				allDeviceModelDrownList: [],
				deviceModelDrownList: [],
				orderSimpleInfoList: [],
			};
		},
		created() {
			if (this.activeName !== '-1') {
				this.status = this.activeName;
			}
			let params = sessionStorage.getItem('orderParams');
			if (params != undefined) {
				let obj = JSON.parse(params);
				this.params = obj;
				if (obj.recordTime != null && obj.recordTime.split(',').length >= 2) {
					this.recordTime = [];
					this.recordTime.push(obj.recordTime.split(',')[0]);
					this.recordTime.push(obj.recordTime.split(',')[1]);
				}

				if (obj.dispatchTime != null && obj.dispatchTime.split(',').length >= 2) {
					this.dispatchTime = [];
					this.dispatchTime.push(obj.dispatchTime.split(',')[0]);
					this.dispatchTime.push(obj.dispatchTime.split(',')[1]);
				}

				if (obj.installTime != null && obj.installTime.split(',').length >= 2) {
					this.installTime = [];
					this.installTime.push(obj.installTime.split(',')[0]);
					this.installTime.push(obj.installTime.split(',')[1]);
				}

				if (obj.installFinishTime != null && obj.installFinishTime.split(',').length >= 2) {
					this.installFinishTime = [];
					this.installFinishTime.push(obj.installFinishTime.split(',')[0]);
					this.installFinishTime.push(obj.installFinishTime.split(',')[1]);
				}

				if (obj.storeIds != null && obj.storeIds != '' && obj.storeIds.split(',').length > 0) {
					this.queryStoreIdList = [];
					let arr = obj.storeIds.split(',');
					for (let i = 0; i < arr.length; i++) {
						this.queryStoreIdList.push(Number(arr[i]));
					}
				}

				if (
					obj.companyIds != null &&
					obj.companyIds != '' &&
					obj.companyIds.split(',').length > 0
				) {
					this.queryCompanyList = [];
					let arr = obj.companyIds.split(',');
					for (let i = 0; i < arr.length; i++) {
						this.queryCompanyList.push(arr[i]);
					}
				}

				if (obj.brandIds != null && obj.brandIds != '' && obj.brandIds.split(',').length > 0) {
					this.queryDeviceBrandIdList = [];
					let arr = obj.brandIds.split(',');
					for (let i = 0; i < arr.length; i++) {
						this.queryDeviceBrandIdList.push(Number(arr[i]));
					}
				}

				if (obj.modelIds != null && obj.modelIds != '' && obj.modelIds.split(',').length > 0) {
					this.queryModelIdList = [];
					let arr = obj.modelIds.split(',');
					for (let i = 0; i < arr.length; i++) {
						this.queryModelIdList.push(Number(arr[i]));
					}
				}

				if (obj.regionIds != null && obj.regionIds != '' && obj.regionIds.split(',').length > 0) {
					this.queryRegionIdList = [];
					let arr = obj.regionIds.split(',');
					for (let i = 0; i < arr.length; i++) {
						this.queryRegionIdList.push(Number(arr[i]));
					}
				}
			}

			this.params.page = this.page;
			this.currentPage = Number(this.page);

			this.queryOrderList();
			this.queryRegionList();
			this.queryShortCutList();
			this.queryUserList();
			this.queryUserList2();
			this.getOrderCount();
			this.getCompanyDrown();
			this.queryDeviceBrandDrown();
			this.queryDeviceModelDrown();
			this.queryDeviceCategoryList();
		},
		methods: {
			authorization,
			statusFormat,
			statusFormatByStatus,
			orderTypeFormat,
			repairTypeFormat,
			updateLocalList() {
				this.orderManageArray.localList = this.getCodeToText(this.fullLocalId);
			},
			getCodeToText( codeArray) {
			console.log("ID",codeArray)
			if (null === codeArray) {
				return null;
			} else if (null === codeArray) {
			return null;
			}

			let area = [];
			const length = Math.min(codeArray.length, 3); // 最多处理三个元素
			for (let i = 0; i < length; i++) {
				area.push(codeToText[codeArray[i]]);
			}
			return area;
			},
      canShowPreOrder(){

        return (this.status==8||this.status==2)&&this.authorization("/home-admin/order/order-manage/subscribe");
      },
			oldOrderNumRemote(query) {
				if (query !== '') {
					this.oldOrderNumLoading = true;
					this.selectOrderSimpleDict(query);
				} else {
					this.orderSimpleInfoList = [];
				}
			},
			validatePhone(rule, value, callback) {
				if (!value) {
					callback();
				} else {
					if (!new RegExp(/^1[0-9][0-9]\d{8}$/).test(value)) {
						callback('请输入正确格式的手机号码!');
					}
					callback();
				}
			},
			queryDeviceCategoryList() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-category/select-category',
				}).then((res) => {
					this.allDeviceCategoryList = res.data.data.list;
					this.deviceCategoryList = res.data.data.list;
				});
			},
			queryDeviceBrandDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-brand/select-device-brand',
				}).then((res) => {
					this.deviceBrandDrownList = res.data.data.list;
				});
			},
			queryDeviceModelDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-brand/select-device-model',
				}).then((res) => {
					this.allDeviceModelDrownList = res.data.data.list;
				});
			},
			getCompanyDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/company-manager/select-company-drown',
				}).then((res) => {
					this.companyDrownList = res.data.data;
				});
			},
			getOrderCount() {
				this.params.status = '';
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-order-count',
					params: this.params,
				}).then((res) => {
					this.orderCount = res.data.data;
				});
			},
			selectOrderSimpleDict(query) {
				let params = { content: query };
				this.axios({
					method: 'get',
					url: '/v1/web/order-info/select-order-simple-dict',
					params: params,
				}).then((res) => {
					this.oldOrderNumLoading = false;
					this.orderSimpleInfoList = res.data.data.list;
				});
			},
			valueInput(value) {
				this.orderManageArray.orderRemark = value;
				this.$forceUpdate();
			},
			textInput() {
				this.textContent = this.textContent.replace(/\r|\n|\s/gi, ' ');
			},
			arouseClick(row) {
				this.arouseParams.status = 2;
				this.arouseParams.id = row.id;
				this.axios({
					method: 'post',
					url: '/v1/h5/order-info/set-order-status/' + this.arouseParams.id,
					params: this.arouseParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.$message({ message: '唤起成功', type: 'success' });
						this.queryOrderList();
					} else {
						this.$message.error(res.data.message);
					}
				});
			},
			cancelArouse(row) {
				//取消唤起
				this.arouseParams.orderId = row.id;
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/arouse-cancel-order',
					params: this.arouseParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.$message({ message: '唤起成功', type: 'success' });
						this.getOrderCount();
						this.queryOrderList();
					} else {
						this.$message.error(res.data.message);
					}
				});
			},
			deletePowerFun(row) {
				if (
					(authorization('/home-admin/order/order-manage/del') || this.userId === row.recordUser) &&
					row.status === 8
				) {
					return true;
				}
				return false;
			},
			//是否展示编辑按钮
			editPowerFun(row) {
				if (
					authorization('/home-admin/order/order-manage/edit') ||
					this.userId === row.recordUser
				) {
					return true;
				}
				return false;
			},
			addShortcut() {
				sessionStorage.setItem('defaultShortcut', this.defaultShortcut);
				var findData = this.shortcutOptions.findIndex(
					(shortcut) => shortcut.id == this.defaultShortcut
				);
				if (findData > -1) {
					this.orderManageArray.orderRemark = this.shortcutOptions[findData].quickWord;
				} else {
					this.orderManageArray.orderRemark = this.shortcutOptions[0].quickWord;
				}
				this.shortcutModel = false;
			},
			confirmBatchAdd() {
				if (
					this.formBatchList.filter(
						(x) =>
							x.companyId == null ||
							x.modelId == null ||
							x.brandId == null ||
							x.storeId == null ||
							x.regionId == null
					).length > 0
				) {
					this.$message.error('导入数据中存在错误，请修复错误数据后重新导入');
					return;
				}
				this.dialogFormBatchAddLoading = true;
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/batch-add-order',
					params: {
						uuid: this.batchUUid,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.$message({
							message: '导入成功',
							type: 'success',
						});
						this.queryOrderList();
						this.dialogFormBatchAddLoading = false;
						this.dialogFormBatchAdd = false;
					} else {
						this.dialogFormBatchAddLoading = false;
						this.$message.error(res.data.message);
					}
				});
			},
			beforeUpload(file, fileList) {
				if (this.$refs.excelImport.uploadFiles.length > 1) {
					this.$refs.excelImport.uploadFiles.shift();
					fileList.shift();
				}

				let param = new FormData(); //创建form对象
				param.append('file', file.raw);
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/read-order-excel',
					data: param,
				}).then((res) => {
					this.formBatchList = res.data.data.list;
					this.batchUUid = res.data.data.uuid;
					this.dialogFormBatchAdd = true;
				});
			},
			analysisData() {
				let array = [];
				let arrayOld = this.textContent.split(' ');
				for (let i = 0; i < arrayOld.length; i++) {
					if (arrayOld[i] !== ' ' && arrayOld[i] !== '') {
						array.push(arrayOld[i]);
					}
				}
				if (array.length > 0) {
					this.$set(this.orderManageArray, 'reserve2', array[0].replace(' ', ''));
				}
				if (array.length > 1) {
					this.$set(this.orderManageArray, 'customerName', array[1]);
				}
				if (array.length > 2) {
					this.$set(this.orderManageArray, 'phone', array[2]);
				}
				if (array.length > 3) {
					this.$set(this.orderManageArray, 'detailAddress', array[3]);
				}
				if (array.length > 4) {
					this.$set(this.orderManageArray, 'storeId', array[4]);
				}
				if (array.length > 5) {
					this.$set(this.orderManageArray, 'pileModel', array[5]);
				}
				if (array.length > 6) {
					this.$set(this.orderManageArray, 'frameNo', array[6]);
				}
			},
			queryOrderList() {
				//获取订单列表
				this.params.status = this.status;
				this.params.recordTime = this.recordTime + '';
				this.params.dispatchTime = this.dispatchTime + '';
				this.params.installTime = this.installTime + '';
        this.params.subscribeTime=this.subscribeTime+'';
        this.params.isDockingRequired = this.isDockingRequired+'';
				this.params.installFinishTime = this.installFinishTime + '';
				this.params.regionIds = this.queryRegionIdList + '';
				this.params.brandIds = this.queryDeviceBrandIdList + '';
				this.params.modelIds = this.queryModelIdList + '';
				this.params.storeIds = this.queryStoreIdList + '';
				this.params.companyIds = this.queryCompanyList + '';
				sessionStorage.setItem('orderParams', JSON.stringify(this.params));
				if (this.params.size === 'all') {
					this.params.size = -1;
				}
				this.axios({
					method: 'get',
					url: '/v1/web/order-info/select-order',
					params: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
          console.log(this.tableData)
					this.total = res.data.data.total;
				});
			},
			queryRegionList() {
				//所属区域下拉
				this.axios({
					method: 'get',
					url: '/v1/web/region-manage/select-region-drop-down',
					params: this.params,
				}).then((res) => {
					// this.regionList=[]
					// let obj = {}
					// obj.id = 0;
					// obj.regionName = "全部"
					// this.regionList.push(obj)
					this.regionList.push(...res.data.data.list);
				});
			},
			queryShortCutList() {
				this.axios({
					method: 'get',
					url: '/v1/web/quick-word-manage/select-quickWord-drop-down',
					params: this.params,
				}).then((res) => {
					this.shortcutOptions.push(...res.data.data.list);
				});
			},
			queryUserList() {
				//所属师傅下拉（只展示正常接单的师傅）
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/select-master-drown',
					params: {
						receivingOrder: this.receivingOrder,
					},
				}).then((res) => {
					this.userList = [];
					// let obj = {}
					// obj.id = 0;
					// obj.userName = "全部"
					// this.userList.push(obj)
					this.userList.push(...res.data.data.list);
				});
			},
			queryUserList2() {
				//所属师傅下拉（包含暂不接单的师傅）
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/select-master-drown',
				}).then((res) => {
					this.userList2 = [];
					let obj = {};
					obj.id = 0;
					obj.userName = '全部';
					this.userList2.push(obj);
					this.userList2.push(...res.data.data.list);
				});
			},
			queryOperateRecordList() {
				this.recordList = [];
				this.isLoadRecordData = true;
				this.isRecordEntry = false;
				this.isRecordEntryEmpty = true;
				//所属区域下拉
				this.axios({
					method: 'get',
					url: '/v1/web/operate-record/list',
					params: this.recordParams,
				})
					.then((res) => {
						this.recordList.push(...res.data.data.list);
						if (this.recordList.length > 0) {
							this.isRecordEntryEmpty = false;
							this.isRecordEntry = true;
						}
					})
					.finally(() => {
						setInterval(() => {
							this.isLoadRecordData = false;
						}, 500);
					});
			},
			handleClick() {
				// this.reset()
				if (this.activeName == -1) {
					this.status = '';
				} else {
					this.status = this.activeName;
				}
				this.params.page = 1;
				this.currentPage = 1;
				this.queryOrderList();
			},
			add() {
				//新增弹出层
				this.dialogFormAdd = true;
				this.textContent = '';
				this.deviceCategoryList = [...this.allDeviceCategoryList];
				this.orderManageArray = {};
			},
			addSubMit(formName) {
				//验证手机号
				this.orderManageArray.brandId = this.orderManageArray.deviceBrandId;
				if (this.orderManageArray.orderType !== 2) {
					this.orderManageArray.repairType = 1;
					this.orderManageArray.oldOrderNum = '';
					this.orderManageArray.faultDescription = '';
				}
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						//验证手机号
						this.axios({
							method: 'get',
							url: '/v1/web/order-info/phone-exits',
							params: {
								phone: this.orderManageArray.phone,
							},
						}).then((res) => {
							if (res.data.data.exits) {
								this.$confirm('该手机号的订单已存在, 是否继续提交?', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning',
								}).then(() => {
									this.addOrder();
								});
							} else {
								this.addOrder();
							}
						});
					}
				});
			},
			addOrder() {
				

				//新增提交
				if (this.addSubStatus) {
					this.addSubStatus = false;
					this.updateLocalList();
					this.orderManageArray.provinceName=this.orderManageArray.localList[0];
					this.orderManageArray.cityName=this.orderManageArray.localList[1];
					this.orderManageArray.areaName=this.orderManageArray.localList[2];
					this.axios({
						method: 'post',
						url: '/v1/web/order-info/add-order',
						data: this.orderManageArray,
					})
						.then((res) => {
							if (res.data.code === 200) {
								this.dialogFormAdd = false;
								this.$message({
									message: '添加成功',
									type: 'success',
								});
								this.getOrderCount();
								this.queryOrderList();
							} else {
								this.$message.error(res.data.message);
							}
							this.addSubStatus = true;
						})
						.catch(() => {
							this.addSubStatus = true;
							this.$message.error('网络异常,请重试!');
						});
				} else {
					this.$message.error('你的操作太快了!');
				}
			},
			showFollow(row){
				this.selectOrderId.orderId = row.id
				this.queryFollowList();
				this.dialogFormFollow = true;
			},
			queryFollowList(){
				//获取跟进列表
				this.axios({
					method: 'get',
					//后端接口
					url: '/v1/web/order-info/get-order-all-follow/'+this.selectOrderId.orderId,
				}).then((res) => {
					this.modelTableData = res.data.data;
				});

			},
			addFollow(orderId){
				this.axios({
					method: 'get',
					//后端接口
					url: '/v1/web/order-info/query-customer/'+ orderId,
				}).then((res) => {
					this.followArr = {
						orderId: orderId,
						customerName: res.data.data.customerName,
						phone: res.data.data.phone,
						followRemark: null
					};
				});
				this.dialogFollowAdd = true;
			},
			addFollowSubMit(formName){
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/order-info/add-follow',
							data: this.followArr,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFollowAdd = false;
								this.$message({
									message: '添加成功',
									type: 'success',
								});
								this.queryFollowList();
								this.getOrderCount();
								this.queryOrderList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			delFollow(row){
				this.axios({
					method: 'delete',
					//后端接口
					url: '/v1/web/order-info/del-follow/'+row.id,
				}).then((res) => {
					if (res.data.code === 200) {
								this.$message({
									message: '删除成功',
									type: 'success',
								});
								this.queryFollowList();
								this.getOrderCount();
							} else {
								this.$message.error(res.data.message);
							}
				});
			},
			editFollow(row){
				this.axios({
					method: 'get',
					//后端接口
					url: '/v1/web/order-info/query-follow/'+ row.id,
				}).then((res) => {
					this.followEdit = {
						id: row.id,
						orderId: this.selectOrderId.orderId,
						customerName: res.data.data.customerName,
						phone: res.data.data.phone,
						followRemark: res.data.data.followRemark,
					};
				});
				this.dialogFollowEdit = true;
			},
			editFollowSubMit(formName){
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/order-info/edit-follow',
							data: this.followEdit,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFollowEdit = false;
								this.$message({
									message: '编辑成功',
									type: 'success',
								});
								this.queryFollowList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			followMulti(){
				if (this.multipleSelection.length < 1) {
					this.$message({ message: '请至少勾选一个订单项', type: 'error' });
					return;
				}
				this.dialogMultiFollow=true;
			},
      //关闭预约对话框
      closePreOrderDialog(){
        this.preOrderTime='';
        this.dialogFormPreOrder = false;
      },
      // 提交预约
      submitPreOrder(){
        console.log("时间是:"+this.preOrderTime)
        if (!this.preOrderTime) {
          // 显示提示信息，提醒用户选择预约时间
          this.$message.error('请先选择预约时间');
          return;
        }
        this.selectedOrders = this.multipleSelection.map(row => ({
          orderId: row.id,
          date: this.preOrderTime
        }));
        this.axios({
          method: 'post',
          url: '/v1/web/order-info/preorder-install-time',
          data: this.selectedOrders,
        }).then((res) => {
          if (res.data.code === 200) {
            this.dialogFormPreOrder = false;
            this.$message({
              message: '提交成功',
              type: 'success',
            });
          } else {
            this.$message.error(res.data.message);
          }
        });
        this.preOrderTime='';
      },
			multiFollowSubMit(){	
				
				this.selectedOrders = this.multipleSelection.map(row => ({
					orderId: row.id,
					customerName: row.customerName, 
					phone: row.phone,
				}));
				this.followList = this.selectedOrders.map(obj => ({
					...obj,
					followRemark: this.followMsg
				}))
				this.axios({
							method: 'post',
							url: '/v1/web/order-info/add-multi-follow',
							data: this.followList,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogMultiFollow = false;
								this.$message({
									message: '提交成功',
									type: 'success',
								});
							} else {
								this.$message.error(res.data.message);
							}
						});

			},
			edit(row) {
				//编辑弹出层
				this.orderManageArray = { ...row };
				console.log(this.orderManageArray);
				this.orderManageArray.regionIdList = [];
				if (row.reserve4 != null) {
					let arrayRegion = row.reserve4.split(',');
					for (let i = 0; i < arrayRegion.length; i++) {
						if (arrayRegion[i] !== '' && arrayRegion[i] !== undefined) {
							this.orderManageArray.regionIdList.push(Number(arrayRegion[i]));
						}
					}
				}

				this.selectCompanyChange(this.orderManageArray.companyId);
				this.selectDeviceBrandChanged(this.orderManageArray.deviceBrandId);
				this.dialogFormEdit = true;
			},
			editSubMit(formName) {
				//编辑提交
				this.updateLocalList();
				this.orderManageArray.provinceName=this.orderManageArray.localList[0];
				this.orderManageArray.cityName=this.orderManageArray.localList[1];
				this.orderManageArray.areaName=this.orderManageArray.localList[2];
				this.orderManageArray.brandId = this.orderManageArray.deviceBrandId;
				if (this.orderManageArray.orderType !== 2) {
					this.orderManageArray.repairType = 1;
					this.orderManageArray.oldOrderNum = '';
					this.orderManageArray.faultDescription = '';
				}
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/order-info/update-order/' + this.orderManageArray.id,
							data: this.orderManageArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormEdit = false;
								this.$message({
									message: '修改成功',
									type: 'success',
								});
								this.getOrderCount();
								this.queryOrderList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			details(row) {
				//跳转订单详情
				this.$router.push({
					path: '/home-admin/order/order-detail',
					query: {
						orderId: row.id,
						activeName: this.activeName,
						page: this.params.page,
					},
				});
			},
			oldDetails(orderNum) {
				let params = { orderNum: orderNum };
				let row;
				this.axios({
					method: 'get',
					url: '/v1/web/order-info/select-order',
					params: params,
				}).then((res) => {
					if (res.data.data.list != null && res.data.data.list.length > 0) {
						row = res.data.data.list[0];
						this.$router.push({
							path: '/home-admin/order/order-detail',
							query: {
								orderId: row.id,
								activeName: this.activeName,
								page: this.params.page,
							},
						});
					} else {
						this.$message({
							type: 'error',
							message: '原订单不存在',
						});
						return;
					}
				});
			},
			deleteOrder(row) {
				//删除订单
				this.axios({
					method: 'delete',
					url: '/v1/web/order-info/delete-order/' + row.id,
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.getOrderCount();
						this.queryOrderList();
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						});
					}
				});
			},
			examines(row) {
				this.dialogFormExamine = true;
				this.examine.orderId = row.id;
				this.examine.orderIds = [];
			},
			batchExamines() {
				//批量审核
				if (this.multipleSelection.length < 1) {
					this.$message({ message: '请至少勾选一个订单项', type: 'error' });
					return;
				}
				this.dialogFormExamine = true;
				this.examine.orderId = '';
				this.examine.orderIds = [];
			},
			batchPreOrder() {
				//批量预约安装
				if (this.multipleSelection.length < 1) {
				this.$message({ message: '请至少勾选一个订单项', type: 'error' });
				return;
				}
				this.dialogFormPreOrder = true;
			},
			examineCancelFun(row) {
				this.dialogFormExamineCancel = true;
				this.examineCancel.orderId = row.id;
			},
			examineOrderCancel(formName, type) {
				this.examineCancel.type = type;
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/examine-order-cancel',
					data: this.examineCancel,
				}).then((res) => {
					if (res.data.code === 200) {
						if (type == 2) {
							this.$message({ message: '拒绝取消成功', type: 'info' });
						} else if (type == 12) {
							this.$message({
								message: '同意取消成功',
								type: 'success',
							});
						}
						this.dialogFormExamineCancel = false;
						this.getOrderCount();
						this.queryOrderList();
					} else {
						this.$message.error(res.data.message);
					}
				});
			},
			examineOrder(formName, type) {
				//驳回订单
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.examine.type = type;
						if (type == 4) {
							this.examine.rejectRemark = this.orderManageArray.examineArea;
						}
						if (this.examine.orderId != '') {
							this.examine.orderIds.push(this.examine.orderId);
						} else {
							this.orderIdList = [];
							for (let i = 0; i < this.multipleSelection.length; i++) {
								this.orderIdList.push(this.multipleSelection[i].id);
							}
							this.examine.orderId = 0;
							this.examine.orderIds = this.orderIdList;
						}

						this.axios({
							method: 'post',
							url: '/v1/web/order-info/examine-order',
							data: this.examine,
						}).then((res) => {
							if (res.data.code === 200) {
								if (type == 4) {
									this.$message({ message: '驳回成功', type: 'info' });
								} else if (type == 6) {
									this.$message({
										message: '审核通过',
										type: 'success',
									});
								}
								this.dialogFormExamine = false;
								//this.params.status=this.activeName;
								this.getOrderCount();
								this.queryOrderList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			settlementOrder(row) {
				//结算订单
				this.settlementParams.orderId = row.id;
				if (row.companyManagerDO !== undefined && row.companyManagerDO !== null) {
					this.settlementParams.orderPrice = row.companyManagerDO.orderPrice;
					this.settlementParams.masterTaskType = row.companyManagerDO.masterTaskType;
					this.settlementParams.masterTaskValue = row.companyManagerDO.masterTaskValue;
					this.settlementParams.salesmanTaskType = row.companyManagerDO.salesmanTaskType;
					this.settlementParams.salesmanTaskValue = row.companyManagerDO.salesmanTaskValue;
					this.settlementParams.additionsTask = row.companyManagerDO.additionsTask;
					if (row.addOutlay == undefined) {
						this.settlementParams.additionsPrice = 0;
					} else {
						this.settlementParams.additionsPrice = row.addOutlay;
					}
				}
				this.calculationPrice();
				this.dialogFormSettlement = true;
			},
			calculationPrice() {
				if (this.settlementParams.orderPrice != '') {
					if (this.settlementParams.masterTaskType == 1) {
						this.settlementParams.masterOrderPrice = (
							(this.settlementParams.masterTaskValue * this.settlementParams.orderPrice) /
							100
						).toFixed(2);
					} else {
						this.settlementParams.masterOrderPrice = this.settlementParams.masterTaskValue;
					}
				}
				if (this.settlementParams.orderPrice != '') {
					if (this.settlementParams.salesmanTaskType == 1) {
						this.settlementParams.salesmanPrice = (
							(this.settlementParams.salesmanTaskValue * this.settlementParams.orderPrice) /
							100
						).toFixed(2);
					} else {
						this.settlementParams.salesmanPrice = this.settlementParams.salesmanTaskValue;
					}
				}
				if (
					this.settlementParams.additionsPrice != '' &&
					this.settlementParams.additionsPrice != undefined
				) {
					if (this.settlementParams.additionsTask != '') {
						this.settlementParams.masterAdditionsPrice = (
							(this.settlementParams.additionsPrice * this.settlementParams.additionsTask) /
							100
						).toFixed(2);
					}
				}
				this.settlementParams.masterPrice =
					Number(this.settlementParams.masterAdditionsPrice) +
					Number(this.settlementParams.masterOrderPrice);
				this.settlementParams.additionsProfitPrice =
					Number(this.settlementParams.additionsPrice) -
					Number(this.settlementParams.masterAdditionsPrice);
				this.settlementParams.orderProfitPrice =
					Number(this.settlementParams.orderPrice) -
					Number(this.settlementParams.masterOrderPrice) -
					Number(this.settlementParams.salesmanPrice);
				this.settlementParams.profitPrice =
					Number(this.settlementParams.additionsProfitPrice) +
					Number(this.settlementParams.orderProfitPrice);
			},
			settleSubMit() {
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/settlement-order',
					data: this.settlementParams,
				}).then((res) => {
					if (res.data.code === 200) {
						this.$message({ message: '结算成功', type: 'success' });
						this.getOrderCount();
						this.queryOrderList();
						this.dialogFormSettlement = false;
					} else {
						this.$message.error(res.data.message);
					}
				});
			},
			cancel() {
				//取消提示/弹出层
				if (this.multipleSelection.length < 1) {
					this.$message({ message: '请至少勾选一个订单项', type: 'error' });
					return;
				}
				this.dialogFormCancel = true;
				this.orderManageArray = {};
			},
			kill() {
				//取消提示/弹出层
				this.orderManageArray = {};
				if (this.multipleSelection.length < 1) {
					this.$message({ message: '请至少勾选一个订单项', type: 'error' });
					return;
				}
				this.$confirm('此操作将撤销派单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
				})
					.then(() => {
						this.orderIdList = [];
						for (let i = 0; i < this.multipleSelection.length; i++) {
							this.orderIdList.push(this.multipleSelection[i].id);
						}
						this.orderManageArray.orderIds = this.orderIdList;
						this.axios({
							method: 'post',
							url: '/v1/web/order-info/kill-order',
							data: this.orderManageArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.$message({ type: 'success', message: '撤销成功!' });
								this.getOrderCount();
								this.queryOrderList();
								this.queryUserList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消',
						});
					});
			},
			distribution() {
				//分配提示/弹出层
				if (this.multipleSelection.length < 1) {
					this.$message({ message: '请至少勾选一个订单项', type: 'error' });
					return;
				}
				this.dialogFormDistribution = true;
				this.orderManageArray = {};
			},
			toExcel(isExportAll) {
				let param = { ...this.params };
				if (!isExportAll) {
					if (this.multipleSelection.length < 1) {
						this.$message({ message: '请至少勾选一个订单项', type: 'error' });
						return;
					}
					this.orderIdList = [];
					for (let i = 0; i < this.multipleSelection.length; i++) {
						this.orderIdList.push(this.multipleSelection[i].id);
					}
					param.orderIds = this.orderIdList;
				}
				this.exportExcelLoading = true;
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/export-excel',
					data: param,
					responseType: 'blob',
				}).then((res) => {
					let filename = res.headers['content-disposition'];
					if (filename == undefined) {
						this.$message.error('权限不足');
						this.exportExcelLoading = false;
						return;
					}

					const link = document.createElement('a');
					let blob = new Blob([res.data], { type: 'application/octet-stream' }); //类型excel
					link.style.display = 'none';
					link.href = URL.createObjectURL(blob);
					link.setAttribute('download', decodeURI(filename.substring(filename.indexOf('=') + 1)));
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.exportExcelLoading = false;
				});
			},
			download() {
				//批量下载
				if (this.multipleSelection.length < 1) {
					this.$message({ message: '请至少勾选一个订单项', type: 'error' });
					return;
				}
				if (this.multipleSelection.length > 15) {
					this.$message({ message: '单次最多只能下载15个订单的数据', type: 'error' });
					return;
				}
				this.fullscreenLoading = true;
				this.orderIdList = [];
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.orderIdList.push(this.multipleSelection[i].id);
				}
				this.axios({
					method: 'post',
					url: '/v1/web/order-info/image-download',
					data: { orderIds: this.orderIdList },
					responseType: 'blob',
				})
					.then((res) => {
						let filename = res.headers['content-disposition'];
						const link = document.createElement('a');
						let blob = new Blob([res.data], { type: 'application/zip' }); //类型excel
						link.style.display = 'none';
						link.href = URL.createObjectURL(blob);
						link.setAttribute(
							'download',
							decodeURI(filename.substring(filename.indexOf('=') + 1, filename.lastIndexOf('.')))
						);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						this.fullscreenLoading = false;
					})
					.catch(() => {
						this.$message.error('下载失败');
						this.fullscreenLoading = false;
					});
			},
			distributionSubMit(formName) {
				//分配订单
				this.orderIdList = [];
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.orderIdList.push(this.multipleSelection[i].id);
				}
				this.orderManageArray.orderId = this.orderIdList;
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/order-info/distribution-order',
							data: this.orderManageArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormDistribution = false;
								this.$message({ message: '分配成功', type: 'success' });
								if (this.orderManageArray.status == 8) {
									this.params.status = 8;
								} else if (this.orderManageArray.status == 1) {
									this.params.status = 1;
								}
								this.getOrderCount();
								this.queryOrderList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			cancelOrder(formName) {
				//取消订单
				this.orderIdList = [];
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.orderIdList.push(this.multipleSelection[i].id);
				}
				this.orderManageArray.orderIds = this.orderIdList;
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					} else {
						this.axios({
							method: 'post',
							url: '/v1/web/order-info/cancel-order',
							data: this.orderManageArray,
						}).then((res) => {
							if (res.data.code === 200) {
								this.dialogFormCancel = false;
								this.$message({ message: '取消成功', type: 'success' });
								if (this.orderManageArray.status == 12) {
									this.params.status = 12;
								} else if (this.orderManageArray.status == 1) {
									this.params.status = 1;
								}
								this.getOrderCount();
								this.queryOrderList();
							} else {
								this.$message.error(res.data.message);
							}
						});
					}
				});
			},
			record(row) {
				this.recordParams.orderId = row.id;
				this.queryOperateRecordList();
				this.dialogFormRecord = true;
			},
			
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			dateFormat: function (row, column, cellValue) {
				if (cellValue !== null && cellValue !== undefined) {
					return moment(cellValue).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			CreateTimeFormat: function (time) {
				if (time !== null && time !== undefined) {
					return moment(time).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryOrderList();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryOrderList();
			},
			checkData() {
				this.params.page = 1;
				this.currentPage = 1;
				this.queryOrderList();
				this.getOrderCount();
			},
			reset() {
				this.currentPage = 1;
				this.params = { page: 1, size: 10 };
				this.recordTime = [];
				this.dispatchTime = [];
				this.installTime = [];
				this.installFinishTime = [];
				this.queryRegionIdList = [];
				this.queryStoreIdList = [];
				this.queryCompanyList = [];
				this.queryDeviceBrandIdList = [];
				this.queryModelIdList = [];
				if (this.activeName !== '-1') {
					this.status = this.activeName;
				}
				sessionStorage.setItem('orderParams', JSON.stringify(this.params));
				this.queryOrderList();
				this.getOrderCount();
			},
			selectCompanyChange(companyId) {
				if (companyId == null || companyId == '') {
					this.deviceCategoryList = [];
					return;
				}

				let company = {
					...this.companyDrownList.find((x) => {
						return x.companyId == companyId;
					}),
				};

				if (company != null && company != undefined && company.categoryIdList != null) {
					let categoryArr = company.categoryIdList;
					let deviceArr = this.allDeviceCategoryList.filter((x) => {
						return categoryArr.indexOf(x.id) >= 0;
					});
					this.deviceCategoryList = [...deviceArr];

					if (categoryArr.indexOf(this.orderManageArray.storeId) < 0) {
						this.orderManageArray.storeId = '';
					}
				}
			},

			selectHouseTypeChanged(houseId){
				this.orderManageArray.houseType = houseId;
			},
			selectDeviceBrandChanged(deviceBrandId) {
				if (deviceBrandId == null || deviceBrandId == '') {
					this.deviceModelDrownList = [];
					return;
				}
				let deviceBrand = {
					...this.deviceBrandDrownList.find((x) => {
						return x.id == deviceBrandId;
					}),
				};

				if (deviceBrand != null && deviceBrand.modelList != null) {
					this.deviceModelDrownList = [...deviceBrand.modelList];
					let thisModel = this.deviceModelDrownList.find((x) => {
						return x.id === this.orderManageArray.modelId;
					});
					if (thisModel == null) {
						this.orderManageArray.modelId = '';
					}
				} else {
					this.deviceModelDrownList = [];
				}
			},
		},
	};
</script>

<style scoped>
	.form {
		padding-left: 20px;
		padding-top: 20px;
		margin-bottom: 10px;
		background-color: #fff;
		border-radius: 8px;
		border-radius: 8px;
	}

	.tab {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding-top: 15px;
		padding-left: 15px;
		padding-right: 15px;
		background-color: #fff;
	}




  .radio-group-merged {
    display: inline-flex;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    overflow: hidden;
  }

  .radio-item {
    padding: 5px 10px;
    flex: 1;
    text-align: center;
    cursor: pointer;
    margin: 0;
    border-right: 1px solid #dcdfe6;
  }

  .radio-item:last-child {
    border-right: none;
  }

  .radio-group-merged .el-radio.is-checked .el-radio__label {
    background-color: #409eff; /* 选中时的背景颜色 */
    color: white; /* 选中时的文字颜色 */
  }

  .radio-item:hover {
    background-color: #f0f9ff; /* 悬浮时的背景颜色 */
  }

  .radio-group-merged .el-radio__input {
    display: none; /* 隐藏单选按钮 */
  }
















	.grid {
		height: 80%;
		background-color: #fff;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
</style>
